import React, { Component } from 'react';
import {Chart as ChartJS} from 'chart.js/auto';
import {CChartDoughnut,CChartBar,CChartPie,CChartLine,CChartPolarArea} from '@coreui/react-chartjs'
interface ChartProps {
    chartData:any;
    options?:{};
    width:any;
    height:any;
}
export const BarChart = ({chartData,options,width,height}:ChartProps)=> {
    return (
      <div style={{width:width?width:"100%",height:height?height:"100%"}}>
        <CChartBar data={chartData} options={options} />
      </div>
    )
  }
  export const LineChart = ({chartData,options,width}:ChartProps)=> {
    return (
      <div style={{width:width?width:"100%"}}>
        <CChartLine data={chartData} options={options} />
      </div>
    )
  }
  export const DoughnutChart = ({chartData,options,width}:ChartProps)=> {
    return (
      <div style={{width:width?width:"100%"}}>
        <CChartDoughnut data={chartData} options={options} />
      </div>
    )
  }
  export const PieChart = ({chartData,options,width}:ChartProps)=> {
    return (
      <div style={{width:width?width:"100%"}}>
        <CChartPie data={chartData} options={options} />
      </div>
    )
  }
