/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import { CheckBoxButton, InputField, SelectField, SubmitButton, TextAreaField } from "../forms/inputField";
import { Formik} from "formik";
import * as y from "yup";
import { PostData, ReturnComma } from "../../includes/function";
import Country from "../../includes/utils/states.json";
import { ReactPhotoEditor } from 'react-photo-editor'
import { AddressProps, CountryProps, ResourcesProps, SubItemModel, SubscriptionProps } from "../../includes/types";
import { BaseLoader } from "../../css/spinnner";
import { AddImage, CheckIcon, TrashIcon } from "../../assets/icons";
import { AddressIcon, EditIcon } from "../icons";
import SubscriptionPlans from "../subscription_plans";
import AmenitiesComponent from "./amenities";
import Moment from "moment";
import axios,{AxiosError,AxiosResponse} from "axios";
import { NewHomeListing } from "./newPost";
export type SwitchSection = "home"|"land"
interface NewListingProps {
    onClose:()=>void;
}
// import 'react-photo-editor/dist/style.css ;'
export const NewListing = (props:NewListingProps)=>{
const [section,setSection] = useState<SwitchSection>('home')
return <div 
className="modal" 
  tabIndex={-1} >
<div className="modal-dialog modal-lg modal-dialog-scrollable" >
<div className="modal-content" style={{marginTop:120}}>
<div className="modal-header" >
    <h5 className="modal-title">Post a property</h5>
    <button onClick={props.onClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> 
</div>
<div className="modal-body p-5" >
<div >
<div className="btn-wrapper d-flex"
>
      <div 
      onClick={()=>{
        setSection("home");
      }}
      className={section === "home"?"tab-btn-active":"tab-btn"}>
        <span className="pe-1">Home</span> {section === "home"?<CheckIcon />:null}
      </div>
      <div
      onClick={()=>{
        setSection("land");
      }}
      className={section === "land"?"tab-btn-active":"tab-btn"}>
        <span className="pe-1">Land</span>{section === "land"?<CheckIcon  />:null}
      </div>
    </div>
<NewHomeListing 
onClose={()=>{}} 
propertyType={section}
/>

</div>
</div>
</div>
</div>
</div>
}

