import React, { CSSProperties } from "react";
import { ItemModel, SubMenuProps } from "../../includes/types";
import { NavLink, useNavigate } from "react-router-dom";
import "./style.css";
import { Style } from "util";
import { CloseIcon } from "../../assets/icons";
interface DropDownProps {
    list?: SubMenuProps[];
    title?: string;
    style?: CSSProperties | undefined;
    onRemove?:(id:any)=>void;
    onValue?:(data:any)=>void;
    onClose?:()=>void;
}
const DropDown = (props: DropDownProps) => {
    const navigate = useNavigate();
    if(props.list?.length === 0)
    {
        return null;
    }
    return <div className='dropdownMain' style={props.style}>
        <div className="dropdownContainer">
            {props.title?<div className="p-3">
                <div className="d-flex align-items-center justify-content-between"> 
                    <p className="mb-0 fs-17 fw-semibold">{props.title}</p>
                    <span className="badge bg-secondary-transparent" id="notifiation-data">5 Unread</span>
                   </div>
                  </div>:null}
                  {props.title?<div className="dropdown-divider"></div>:null}
            <ul className="mb-0">
                {props.list?.map((sub, i) => <div className="dropdownItem" key={i} >
                <li className="dropdown-item"> 
                    <div className="d-flex align-items-center gap-1"> 
                        {sub.icon?sub.icon:null} 
                                <div className="flex-grow-1 d-flex align-items-center justify-content-between"> 
                                    <div> 
                                     <p className="mb-0 fw-semibold "><button className={"btn p-0 m-0"} onClick={()=>{
                                      if(props.onValue)
                                      {
                                        return props.onValue(sub);
                                      }
                                      navigate(sub.route);
                                       if(props.onClose)
                                       {
                                       props.onClose();
                                       }
                                     }} style={{lineHeight:1,fontSize:14,fontWeight:"normal"}}>{sub.title}</button></p>
                                    {sub.description?<span style={{lineHeight:0}} className="text-muted fw-normal fs-12 header-notification-text">{sub.description}</span>:null}
                                    </div> 
                                    <div className="close-btn"> 
                                        {props.onRemove?<button 
                                        onClick={()=>{
                                            if(props.onRemove)
                                            {
                                            props.onRemove(i);
                                            }
                                        }}
                                        className="btn p-0">
                                            <CloseIcon
                                            color="gray"
                                            size={15}
                                            />
                                            </button>:null} 
                                            </div> 
                                            </div> 
                                            </div> 
                                            </li>
                </div>)}
            </ul>
        </div>
    </div>
}
export default DropDown;