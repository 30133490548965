/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect,  useState } from "react";
import { useLocation } from "react-router-dom";
import {CChart,CChartBar,CChartPie,CChartLine,CChartPolarArea} from '@coreui/react-chartjs'
import {CRow,CCard,CCardTitle,CCardBody,CTableBody,CTableDataCell,CTableRow,CTableHeaderCell,CTable,CTableHead,CCallout,CWidgetStatsB,CWidgetStatsC,CWidgetStatsD,CDropdownItem,CDropdownMenu,CCol,CWidgetStatsA,CDropdown,CDropdownToggle} from "@coreui/react"
import { VerticalDots } from "../../../components/icons";
import { GetData, PostData, ReturnComma } from "../../../includes/function";
import { ItemProps, TransactionHistoryProps } from "../../../includes/types";
import backdrop from "../../../images/dashboard-backdrop.png";
import Moment from "moment";
import DonutChart from "../user/charts/donut";

import { Currencies } from "../../../includes/constants";
import DashboardCardView from "../../../components/card";
import { CardContainer } from "../../../components/cards/cardContainer";
import { BarChart } from "../../../components/chartComponents";
interface DashboardDataProp {
  totalRevenue:number;
  totalUsers:number;
  totalProperties:number;
}
const DashboardAnalytics = ()=>{
const location = useLocation();
const data = {
  labels: [
    'Red',
    'Green',
    'Yellow',
    'Grey',
    'Blue'
  ],
  datasets: [{
    label: 'My First Dataset',
    data: [11, 16, 7, 3, 14],
    backgroundColor: [
      'rgb(255, 99, 132)',
      'rgb(75, 192, 192)',
      'rgb(255, 205, 86)',
      'rgb(201, 203, 207)',
      'rgb(54, 162, 235)'
    ]
  }]
};
const [loading,setLoading] = useState<boolean>(false)
const [limit,setLimit] = useState<number>(8)
const [dashboardData,setDashboardData] = useState<DashboardDataProp>({
  totalRevenue:0,
  totalUsers:0,
  totalProperties:0,
})
const [list,setList] = useState<TransactionHistoryProps[]>([])

const GetHistory =()=>{
  PostData("transaction_history",{
    limit:limit
  },false).then((res)=>{
  if(res.status)
  {
  setList(res.result)
  }
  })
}
const GetDashboard =()=>{
  GetData("dashboard",{},false).then((res)=>{
  if(res.status)
  {
  setList(res.result)
  }
  })
}
useEffect(()=>{
  GetDashboard()
},[])


return <div className="dashboard">
<div 
className="row"
>
<div 
className="col-3 "
>
<div 
className="card p-0 overflow-hidden col-12 mb-3"
style={{background:`url(${backdrop})`,backgroundSize:"cover"}}
>
  <div className="p-4 row" style={{backgroundColor:"rgba(0,147,239,0.77)"}} >
  <div className="col-12">
<DonutChart
size={180}
/>
</div>
<div className="col-12">
  <div className="white-text text-muted mb-0">slslllslls ddkd dkdd dkkd dkddkd dkd kd</div>
  <div className="white-text fs-6">{Currencies.naira.symbol}{ReturnComma("39938900")}</div>
</div>
 
</div> 
</div>
<CardContainer >
  <div></div>
  </CardContainer> 
  <CardContainer >
  <div></div>
  </CardContainer>
</div>
<div 
className="col-9 gap-3"
>
<div 
className="row mb-3"
>
<div 
className="col-4"
>
<DashboardCardView
title="Total revenues"
data={"10000"}
/> 
</div>
<div 
className="col-4"
>
<DashboardCardView
title="Total users"
data={"20000"}
 />
</div>
<div 
className="col-4"
>
<DashboardCardView
title="Total transactions"
data={"20000"}
 />
</div>
</div>
<CardContainer
title="Monthly subscription"
>
<div >
  <BarChart 
  chartData={{
    series: [
    {
    data: [44, 55, 41, 64, 22, 43, 21]
  }, 
  {
    data: [53, 32, 33, 52, 13, 44, 32]
  }],
    chart: {
    type: 'bar',
    height: 430
  },
  plotOptions: {
    bar: {
      horizontal: true,
      dataLabels: {
        position: 'top',
      },
    }
  },
  dataLabels: {
    enabled: true,
    offsetX: -6,
    style: {
      fontSize: '12px',
      colors: ['#fff']
    }
  },
  stroke: {
    show: true,
    width: 1,
    colors: ['#fff']
  },
  tooltip: {
    shared: true,
    intersect: false
  },
  xaxis: {
    categories: [2001, 2002, 2003, 2004, 2005, 2006, 2007],
  }
  }}
  width={"100%"}
  height={"280px"}
  options={{}}
  />
</div>
</CardContainer >
<CardContainer
title="Users"
>
<table className="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td colSpan={2}>Larry the Bird</td>
      <td>@twitter</td>
    </tr>
  </tbody>
</table>
</CardContainer >

<CardContainer 
title="Transactions"
>
<table className="table">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">First</th>
      <th scope="col">Last</th>
      <th scope="col">Handle</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <th scope="row">1</th>
      <td>Mark</td>
      <td>Otto</td>
      <td>@mdo</td>
    </tr>
    <tr>
      <th scope="row">2</th>
      <td>Jacob</td>
      <td>Thornton</td>
      <td>@fat</td>
    </tr>
    <tr>
      <th scope="row">3</th>
      <td colSpan={2}>Larry the Bird</td>
      <td>@twitter</td>
    </tr>
  </tbody>
</table>
</CardContainer>
</div>
</div>
</div>
}
export default DashboardAnalytics;