import { useState } from "react"
import { CheckIcon, SearchIcon } from "../../assets/icons"
import BaseModal from "../baseModal"
import { CardContainer } from "../cards/cardContainer"
import { InputField, SubmitButton } from "../forms/inputField"
import { SearhBarComponent } from "../searchbar"
import { BaseLogo } from "../../images"
import { BaseLoader } from "../../css/spinnner"
import useHttpHooks from "../../includes/httpHooks"
import { AddressIcon, InfoIcon } from "../icons"
interface GoogleAddressProp {
    onClose:()=>void;
    onValue:(data:AddressListProp)=>void;
    title?:string;
}
interface AddressListProp {
    address?:string;
    state?:string;
    country?:string;
    latitude?:string;
    longitude?:string;
}
export const GoogleAddress = (prop:GoogleAddressProp)=>{
    const [searchText,setSearchText] = useState<string>("");
    const [list,setList] = useState<AddressListProp[]>([]);
    const [selectedAddress,setSelectedAddress] = useState<AddressListProp | null>(null);
    const {loading,SearchAddress} = useHttpHooks();
    return <BaseModal
    title={prop.title}
    onClose={prop.onClose}
    type="md"
    center
    >
    <div >
    <div className="alert alert-info" >
        <InfoIcon size={20}/>
         <span className="px-2">Search for your addres in the input below and choose from the list display then click done</span>
       </div>
       <InputField 
       label="Search address"
       placeholder="Enter your addres here..."
       name="address"
       type="text"
       max={50}
       value={searchText}
       onValueChange={({target})=>{
        setSearchText(target.value);
        SearchAddress(target.value).then((result)=>{
            if(result.status)
            {
                setList(result.data);
            }
        })
       }}
       leftIcon={<SearchIcon size={30} />}
       rightIcon={<div style={{marginTop:-10}}>{loading &&<BaseLoader size="small" />}</div>}
       /> 
       
       <ul className="list-group mt-3">
        {list.map((address,i)=><li 
        className="list-group-item cursor"
        key={i}
        onClick={()=>setSelectedAddress(address)}
        >
          <AddressIcon size={20} />  {address.address} 
          {address === selectedAddress && <CheckIcon />}
        </li>)}
    </ul>
    <div >
        <SubmitButton 
        disabled={selectedAddress === null}
        title="Done"
        submit={()=>{
            prop.onValue(selectedAddress!);
        }}
        />
    </div>
    </div>
    </BaseModal>
       
}