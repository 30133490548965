import { RefObject, useEffect, useRef, useState } from "react";
import { ResourcesProps } from "../../../includes/types"
import { MoreImagesIcon } from "../../moreImages/icons";
import "./index.css";
import Dropzone from "react-dropzone";
import { PostData } from "../../../includes/function";
import useHttpHooks from "../../../includes/httpHooks";
import { TrashIcon } from "../../../assets/icons";
import { EditIcon } from "../../icons";

import BaseModal from "../../baseModal";
import ImageEditorComponent from "../../imageEditor";
import { BaseLoader } from "../../../css/spinnner";
import { useTransition, animated } from '@react-spring/web'
import SubscriptionPlans from "../../subscription_plans";


interface ImageSliderProp {
    list:ResourcesProps[];
    updateImages:(list:File[])=>void;
    numberOfImagesSubscribed:number;
}
export const ImageSlider = (prop:ImageSliderProp)=>{
    const [listOfImages,setListOfImages]= useState<File[]>([]);
    const [editedIndex,setEditedIndex]= useState<boolean>(false);
    const [selectedImage,setSelectedImage]= useState<File | null>(null);
    const [selectedFile,setSelectedFile]= useState<File | null>(null);
    const innerContainer = useRef<HTMLDivElement>(null);
   
    const handleImageSelection = (f:File)=>{
        const im = new Image();
        im.src = URL.createObjectURL(f);
        im.onload = ()=>{
            const imWith = im.width;
            const imHeight = im.height;
            setSelectedFile(f);
        }
    }
    useEffect(()=>{
        prop.updateImages(listOfImages);
    },[listOfImages]);
    const ImageCount = prop.numberOfImagesSubscribed - listOfImages.length;
    return <>
    <div className="sliderContainer">
    {listOfImages.length === 0? <div className="sliderImage" 
    >
    <Dropzone 
      accept={{
        "image/*": ['.jpeg', '.png']
      }
      }
    onDrop={acceptedFiles => {
        handleImageSelection(acceptedFiles[0])
        }}>
    {({getRootProps, getInputProps}) => (<>
    <div className="imageItem text-center"  {...getRootProps()} >
           <input {...getInputProps()} />
           <div className="mt-5 mb-3">Select or Drag and drop image</div> 
            <span className="cursor" >
            <MoreImagesIcon size={40}/>
            </span>
     </div>
     </>)}
     </Dropzone>
     </div>:<div 
     ref={innerContainer}
     className="sliderImage">
   {selectedImage && <div 
     className={`imageItem active`}  >
        <img 
          alt={`img-1`}  
            src={URL.createObjectURL(selectedImage)} />
     </div>}
    </div>}
    {listOfImages.length !== 0 && ImageCount !== 0?<div className="add-more-icon box-shadow">
        <Dropzone 
        accept={{
            "image/*": ['.jpeg', '.png']
          }
          }
     onDrop={acceptedFiles => {
      handleImageSelection(acceptedFiles[0])
         }}>
       
       {({getRootProps, getInputProps}) => (<div {...getRootProps()}>
     <input {...getInputProps()} />
     <MoreImagesIcon />
     <span className="px-2">{`Add ${ImageCount} more image${ImageCount !== 1?"s":""}`}</span>
     </div>)}
     </Dropzone>
     </div>:null}
     {listOfImages.length !== 0?<div className="slider-indicator box-shadow" >
    {listOfImages.map((a,i)=><div 
    onClick={()=>{
        setSelectedImage(a);
    }}
    className={a === selectedImage?"activeDot":"inactiveDot"} key={i} ></div>)}
    </div>:null}
    {listOfImages.length !== 0 &&<div className="slider-icon-wrpper box-shadow">
         <div 
            onClick={()=>{
                setEditedIndex(false);
                const list = listOfImages.filter((a,i)=>a !== selectedImage)
               setListOfImages(list);
               setSelectedImage(list.filter((a,i)=>i == 0)[0]);
            }}
            className="slider-icon">
            <TrashIcon size={20}/>
         </div>
         <div
         onClick={()=>{
           setEditedIndex(true);
           setSelectedFile(selectedImage);
         }}
         className="slider-icon">
            <EditIcon size={15}/>
         </div>
         </div>}
    </div>
    {selectedFile !== null ?<ImageEditorComponent 
    onClose={()=>setSelectedFile(null)}
    onValue={(editedImage:File)=>{
        var countImages:any[] = [];
        if(editedIndex)
        {
            countImages = listOfImages.map((a,i)=>{
                if(a === selectedImage)
                {
                    a = editedImage;
                }
                return a;
            })
        }else{
        countImages = [...listOfImages,editedImage];
        }
        setListOfImages(countImages);
        setSelectedFile(null);
        setSelectedImage(countImages[0]);
    }}
    file={selectedFile}
    />:null}
    
    </>
}
