import { ReactNode } from "react"

interface CardContainerProp {
    children:ReactNode;
    title?:string
}
export const CardContainer = (prop:CardContainerProp)=>{
    return <div className="card custom-card overflow-hidden mb-3">
    <div className="card-header">
        {prop?.title}
      </div>
    <div className="card-body">
    {prop.children}
    </div>
    <div className="card-footer text-muted">
        2 days ago
      </div>
    </div>
}