import React from 'react';
import Chart from 'react-apexcharts';
interface DonutChartProp {
    size:number
}
const DonutChart = (prop:DonutChartProp)=>{
    const chartData:any ={
        chart: {
            type: 'donut', // Specifies a donut chart
        },
        labels: ['A', 'B', 'C', 'D'], // Labels for each slice
        series: [44, 55, 13, 43], // Data for each slice
        colors: ['#FF4560', '#00E396', '#008FFB', '#FEB019'], // Colors for each slice
        stroke: {
            width: 0, // Remove stroke lines around each slice
        },
        dataLabels: {
            enabled: true, // Show data labels
            style: {
                fontSize: '14px',
                fontWeight: 'bold',
                colors: ['#fff'],
            },
        },
        legend: {
            position: 'bottom', // Position of the legend
            horizontalAlign: 'center', // Align the legend horizontally in the center
            fontSize: '14px', // Font size for legend
        },
      };
return <Chart 
options={chartData}
series={chartData.series} // Pass the series data
type="donut" // Set chart type to donut
height={prop.size} // Set the height of the chart
/>
}
export default DonutChart;