/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import './style.css';
import BackDrop from './images/developers.png';
import DeveloperSearhBarComponent, { SearchProps } from "../../components/searchbar/developer";
import { DevelopersProps } from "../../includes/types";
import { NavLink } from "react-router-dom";
import { ROUTES, blueColor } from "../../includes/constants";
import Pagination from "../../components/pagination";
import { PostData } from "../../includes/function";
import RatingView from "../../components/rating";
import { AdvertView } from "../../components/adverts";
import { BaseLoader } from "../../css/spinnner";
import mapPin from '../../assets/icons/map-pin.svg'
const DeveloperScreen = ()=>{
  const [loading,setLoader] = useState<boolean>(false);
const [savedlist,setSavedList] = useState<DevelopersProps[]>([]);
const [list,setList] = useState<DevelopersProps[]>([]);
const [searchResult,setsearchResult] = useState<DevelopersProps[]>([]);
const [searched,setSearched] = useState<boolean>(false);
const GetList = ()=>{
  setLoader(true);
  PostData("developers_list",{
    page:1,
    address:"Nigeria"
  },false).then((res)=>{
    setLoader(false);
    if(res.status)
    {
    setList(res.data)
    setSavedList(res.data)
    }
  })
}
useEffect(()=>{
  GetList();
},[])


return <main className='content' style={{minHeight:600}} >
<div className='image-section'
style={{backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.6)),url("${BackDrop}")`,  backgroundRepeat: "no-repeat",
backgroundSize:"cover"}} >

<div className="position-absolute text-center" style={{height:"100%",width:"100%"}} >
    <h2 className="pt-5 t1" >Real Estate Developers</h2>
    <p className="fs-4 t2 ">List of the popular real estate companies, developers in Nigeria with verified properties.</p>
    <div className="row" >
    <div className="col-3" >
    </div>
    <div className="col-6" >
    <DeveloperSearhBarComponent
    onValue={(d:SearchProps)=>{
        setSearched(true);
        setLoader(true);
        const x:any = d;
        if(d.state === "")
        {
          x.state = "*";
        }
        if(d.city === "")
        {
          x.city = "*";
        }
        if(d.query === "")
        {
          x.query = "*";
        }
        if(d.rating === 0)
        {
          x.rating = "*";
        }
        
        PostData("developers_list_search",x).then((res)=>{
          setList(res.result);
          setLoader(false); 
          setsearchResult(res.result)
          if(res.result.length === 0)
          {
            setList(savedlist);
          }
        })
    }}
    />
    </div>
    <div className="col-3" ></div>
    </div>
</div>
</div>
<div className='container pt-5 pb-5' >
<div className='row' >
<div className='col-3' >
<AdvertView 
type="home"
size="small"
/>
</div>
<div className='col-9' >
  {loading?<div className="p-3">
    <BaseLoader 
    color={blueColor}
    size="small"
    />
    <span className="ps-3" >fetching...</span>
  </div>:searchResult.length === 0 && searched?<div className="alert alert-danger" >
    Item not found!
  </div>:null}
{list.map((a,i)=><>
{a.adverts && <AdvertView 
type="home"
/>}
<div key={i} className="pb-3">
<div className="card row" style={{height:130}}>
<div className="col-2 p-2">
<img  className="thumbnail thumbnail-list"
  src={a.logo}
  style={{height:100,width:100,objectFit:"contain"}}
   />
</div>
<div className="col-10 pt-3">
  <div>
    <NavLink to={(`/developer/${a.id}`).toLowerCase()} ><b>{a.name}</b></NavLink></div>
   <small className="">{String(a.description).substring(0,190)}...</small>
   <div className="row" >
   <div className="col-12" >
    <div className="row" >
    <div className="col-5" >
    <div className='address' >
  <img src={mapPin} 
  style={{width:18}}
  /> 
  {a?.city}, {a.state}, Nigeria
  </div>
    </div>
    <div className="col-2" >
    
    </div>
    <div className="col-5" >
    <small className='address'>No of Projects: <span className="blueText">{a.noOfProjects}</span></small>
    <RatingView
    value={a.rating?a.rating:0}
    />
 </div>
    </div>
   </div>
   
 </div>
 </div>
</div>
</div>
</>)}
<Pagination />
</div>
</div>
</div>
</main>
}
export default DeveloperScreen;