/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import { BaseLoader } from "../../css/spinnner";
import { GetData, PostData } from "../../includes/function";
import { SubscriptionProps } from "../../includes/types";
import { SubmitButton } from "../forms/inputField";
const SubscriptionPlans = (props:{onClose:()=>void})=>{
    const [loading,setLoading] = useState<boolean>(false)
    const [plans,setPlans] = useState<SubscriptionProps[]>([])
    const [activePlan,setActivePlans] = useState<SubscriptionProps>({})
    const [subscriptionPlans,setSubscriptionPlans] = useState<SubscriptionProps[]>([])
    const GetSubscriptionPlans = ()=>{
        GetData("app_settings",{},false).then((res)=>{
        // alert(JSON.stringify(res))
        if(res.status)
        {
          const found = res.data.subscriptionPlans.find((a:any,i:number)=>a.status === "active")
          if(found)
          {
            setActivePlans(found)
          }
          setSubscriptionPlans(res.data.subscriptionPlans);
        }
        })
    }
    useEffect(()=>{
        GetSubscriptionPlans()  
    },[])
    return <div className="modal" >
       <div className="modal" tabIndex={-1}>
  <div className="modal-dialog modal-lg" >
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title">Subscription plans</h5>
        <button onClick={props.onClose} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="d-flex justify-content-center align-items-center">
            {loading && <BaseLoader />}
        </div>
        <div className="row" >
        {subscriptionPlans.map((a,i)=><div key={i} className="col-4" >
        <div key={i} className="card p-3" style={{height:300,position:"relative",overflow:"hidden"}} >
        {a?.status === "active" &&<small className="fs-10 position-absolute typeView" style={{top:10,left:10,fontSize:12,backgroundColor:"green"}}>Active Plan</small>}
          <div className="mt-3">
            <b>{a.name}</b>
          </div>
          <ul className="px-3">
            <li>Images: {a.numberOfImages}</li>
            <li>Videos: {a.numberOfVideos}</li>
            <li>Virtual Tours: {a.numberOfVideos}</li>
            <li>SMS: {a.smsMessages}</li>
            <li>WhatsApp: {a.whatAppMessages}</li>
            <li>Email Notifications: {a.emailMessages}</li>
          </ul>
          <SubmitButton 
          title="Subscribe"
          />
          </div>  
        </div>)}
        </div>
      </div>
    </div>
  </div>
</div> 
    </div>
}
export default SubscriptionPlans;