/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-empty */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-no-useless-fragment */
import React, { useContext,RefObject, useEffect, useRef, useState } from 'react'
import './style.css';
import compareIcon from './icons/compare.svg';
import { ActionProps, AgentDetailProps, DevelopersProps, ItemProps, ResourcesProps, UserDataProps } from '../../includes/types';

import mapPin from '../../assets/icons/map-pin.svg'
import { NavLink, Navigate, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AddressIcon, EditIcon, EmailIcon, MobileIcon } from '../../components/icons';
import { AddListingIcon } from '../../images';
import { CheckIfUserLoggedin, GetData, MarkEmail, MarkMobileNumber, PostData, SendToast } from '../../includes/function';
import { CheckIcon, CloseIcon, ReturnIcons, TrashIcon, VideoIcon, VirtualIcon } from '../../assets/icons';
import { PlaceHolderAvatar, RELOAD, ROUTES, blueColor } from '../../includes/constants';
import { AppContextModel } from '../../components/appContext';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Formik } from 'formik';
import * as y from 'yup';
import Moment from 'moment';
import { BaseLoader } from '../../css/spinnner';
import { InputField, SelectField, SubmitButton, TextAreaField } from '../../components/forms/inputField';
import PaymentView from '../../components/payment';
import HeartIcon from './icons/heart';
import PrinterIcon from './icons/print';
import Pagination from '../../components/pagination';
import moment from 'moment';
import { ReportView } from '../../components/report';
import { CommentList } from '../developers/details';
import { loginBtnRef } from '../../App';
import { VideoTourFrame } from '../../components/videoTour';
import { VirtualTourFrame } from '../../components/virtualTour';
import { toast } from 'react-toastify';

const Schema = y.object({
  comment:y.string().required("Comment is required.").max(1000),
})
const ImageGallery = () => {
  const [loading,setLoading] = useState<boolean>(false);
  const [showVideoModal,setShowVideoModal] = useState<boolean>(false);
  const [showVirtualModal,setShowVirtualModal] = useState<boolean>(false);
  const [liked,setLike] = useState<boolean>(false);
  const [loadingLike,setLoadingLike] = useState<boolean>(false);
  const [selectedIndex,setSelectedIndex] = useState(0);
  const [details,setDetails] = useState<ItemProps>({})
  const [thumbnail,setThumbnail] = useState<string>("")
  const [list,setList] = useState<ResourcesProps[]>([])
  const {pathname} = useLocation();
  const navigate = useNavigate();
  const pCon = useRef<HTMLDivElement>() as RefObject<HTMLDivElement>;
  const GetWishlistItem = (id:string)=>{
    GetData("get_wishlist_item",{
      id:id
    },false).then((res)=>{
      setLike(res.status);
  })
  }
  const GetDetails = ()=>{
    const params = String(pathname).split("/")
    const id = params.filter((a,i)=>i === params.length - 1).join("");
    setLoading(true);
    GetData("house_details",{
      id:id
    },false).then((res)=>{
      setLoading(false);
    if(res.status)
    {
      GetWishlistItem(res.data.id)
      setThumbnail(res.data.thumbnail)
      setDetails(res.data)
      setList([{uri:res.data.thumbnail},...res.data.images])
    }else{
      toast.error("Item not found",{
        position:"top-center"
      })
      navigate(ROUTES.HousesScreen)
    }
  })
  }
  const handleWishlist = ()=>{
    if(!liked)
    {
    setLoadingLike(true);
    PostData("save_to_wishlist",{
      propertyId:details.id,
      status:liked?1:0
    }).then((res)=>{
      setLoadingLike(false);
      setLike(!liked);
    })
  }
  }
  const [displayResources,setDisplayResources] = useState<ResourcesProps>()
  useEffect(()=>{
    GetDetails();
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },[])
if(!pathname || String(pathname).split("/").length !== 3)
{
  return <Navigate to="/" />
}
if(loading)
{
  return <div className='p-5'>
    <span className='small px-2' >Fetching details...</span>
    <BaseLoader />
  </div>
}

  return (<div >
    <div className='imageGalleryContainer'
    ref={pCon}
  >
     <img 
    src={thumbnail}
    style={{width:"100%",height:"100%",objectFit:"contain"}}
    /> 
    <div className='imageThumbsContainner'>
    {list.map((a,i)=><div key={i} onClick={()=>{
    //  setDisplayResources(a);
     setSelectedIndex(i);
     setThumbnail(String(a.uri));
    }}
     className={`${selectedIndex === i?"imageThumbsActive":"imageThumbs"}`} >
      <img 
      src={a.uri}
       />
    </div>)}
    </div>
    <div className='imageGalleryBlind p-5 item-details' >
    <div className='row'>
  <div className='col-8'>
 <div className='py-3'>
  <span className='whiteBtnSm'>
    {String(details?.type).toUpperCase()}
  </span>
  {details?.featured && <span className='blueBtnSm'>
    Featured
  </span>}
  {details?.hot && <span className='redBtnSm'>
    Hot
  </span>}
  {details?.trendy && <span className='yellowBtnSm'>
    Trendy
  </span>}
</div>
<div  className={"white-text"}>
<h2><span >{details?.title}</span> 
</h2>
</div>
<div 
className='address white-text' >
<img src={mapPin} 
style={{width:18}}
/> 
{details?.state}, {details?.country} <span className='priceDetails' >
{details?.currency}{details?.price}
</span>

</div>
</div>
<div className='col-4 d-flex pt-5'>
<div  
className={'iconsContainer position-relative'} 
onClick={handleWishlist}
> 
{loadingLike?<BaseLoader 
color={blueColor}
size='small'
/>:<HeartIcon 
color={liked?"red":"white"}
size={22}
/>}
<div className='tips'>Like</div>
</div>
{/* <div  
className='iconsContainer position-relative' >
<PrinterIcon
size={25}
/>
<div className='tips'>Print</div>
</div> */}

<div  
className='iconsContainer position-relative' 
onClick={()=>setShowVirtualModal(true)}
>
<VirtualIcon
size={25}
/>
<div className='tips'>Virtual Tour</div>
</div>
<div  
className='iconsContainer position-relative'
onClick={()=>setShowVideoModal(true)}
>
<VideoIcon 
color='white'
size={25}
/>
<div className='tips'>Video Tour</div>
</div>
<ReportView
onValue={()=>{
return ;
}}
postId={details.id!}
postType={details.type!}
/>
</div>
   </div>
    </div>
    </div>
    <div className='p-5'>
    <div className='row'>
    <div className='col-lg-8'>
  <div >
    <h5>Overview </h5> 
    <div className='pb-3'>
{details?.amenities?.filter((a,i)=>i < 5)?.map((a,i)=>{
  return <span className='amenitiesIconsLarge lightBlue' key={i} >
  <div className='d-flex justify-content-center align-item-center icon-w' >
  <img
  src={a.icon} 
  style={{width:25}}
  />
  <span className='ps-2 text-uppercase fs-7'>{a.name}</span>
  </div>
  </span>})}
 </div>
    <div className='pb-3'>
    <h5>Description</h5>
    <p>
    {details?.description}
    </p>
  </div>
  <div className='pb-3'>
    <h5>Amenites</h5>
    <div className='row' >
    {details?.amenities && details?.amenities.map((a,i)=><NavLink key={i} to={`/search?q=${String(a.name).toLowerCase()}&type=${String(details.type).toLowerCase()}`} className='col-3' ><div >
      <span className='p-2 fs-7 fw-bold' style={{color:blueColor}}>
      <img
  src={a.icon} 
  style={{width:20}}
  />
  <span className='ps-3'>{String(a.name).toUpperCase()}</span>
        </span></div></NavLink>)}
  </div>
  </div>
  </div>
  <div className='pb-3'>
    <h5>Location on Map</h5>
    <div className='mapContainer' style={{height:300}}  >
      <iframe 
      src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDHlvqtpYvHYYqE-EnifXQGn8KXu8Bc31Y&q=${details?.state},${details?.country}`} 
      style={{width:"100%",height:"100%"}}
      ></iframe>
    </div>
  </div>
  <div className='pb-3'>
    <h5>Comments <CommentButton 
    id={String(details?.id)}
    postType='house'
    /></h5>
    {details.id ?<CommentList
    id={String(details.id)}
    type='house'
    />:null}
  </div>
  </div>
  <div 
  className='col-lg-4 tourSection' 
  >
  <BookATour
  agentId={String(details?.host_id)}
  propertyId={String(details?.id)}
  />
  </div>
  </div>
  </div>
  {showVideoModal && <VideoTourFrame 
  id={String(details?.property_id)}
  onClose={()=>setShowVideoModal(false)}
  uri={String(details?.thumbnail)}
  type={String(details?.type)}
  />}
   {showVirtualModal && <VirtualTourFrame
  id={String(details?.property_id)}
  onClose={()=>setShowVirtualModal(false)}
  type={String(details?.type)}
  />}
  </div>
  )
}

export default ImageGallery;
const TourSchema = y.object().shape({
  selectedDate:y.string().required("Date is required."),
  selectedTime:y.string().required("Time is required."),
  message:y.string().required("Message is required."),
  agree:y.bool().oneOf([true], 'Field must be checked')
},
[
  ["selectedDate","selectedDate"],
  ["selectedTime","selectedTime"],
  ["message","message"],
  ["email","email"],
  ["mobile","mobile"],
  ["dialCode","dialCode"],
  ["agree","agree"]
])
export const BookATour = ({agentId,propertyId,profile}:{agentId:string;propertyId?:string;profile?:boolean;})=>{
  const navigate = useNavigate();
  const thisForm = useRef() as RefObject<any>;
  const [agentObject,setAgentObject] = useState<UserDataProps | undefined>()
   const [loading, setLoading] = useState<boolean>(false);
   const locals:string | null = localStorage.getItem("userData")
  const [UserDetails,setUserData] = useState<UserDataProps>()
   const GetHostDetails = (agentId:string)=>{
      GetData("host_details",{
        id:114//agentId
      },false).then((res)=>{
        if(res.status)
        {
          setAgentObject(res.data)
        }
      })
   }
   const SendMessage = (values:any)=>{
    setLoading(true);
    PostData("tour_request",values).then((res)=>{
    setLoading(false);
    })
   }
const [paymentAmount,setPaymentAmount] = useState<string>("")
   useEffect(()=>{
     if(locals !== null)
     {
      const u:UserDataProps = JSON.parse(locals)
      setUserData(u);
      thisForm.current.setFieldValue("email",u.email);
      thisForm.current.setFieldValue("mobile",u.mobile);
     }
   },[locals])
   useEffect(()=>{
    const saveData:string | null = localStorage.getItem(`tour${propertyId}`);
    if(saveData !== null)
    {
      const data = JSON.parse(saveData);
      thisForm.current.setFieldValue("selectedDate",data.selectedDate);
      thisForm.current.setFieldValue("selectedTime",data.selectedTime);
      thisForm.current.setFieldValue("message",data.message);
    }
    GetHostDetails(agentId);
 },[])
   return <>
    {agentObject?.firstName && <AgentInfo 
    {...agentObject}
    />}
  {agentObject?.firstName && <div className='row mb-3'>
    <div className='agentTitle'>View my listing</div>
     <div className='tourContainer p-3'>
    <div className='blueText' >Total listing ({agentObject?.total_listing})
    </div>
    <div className='row mb-3' >
    <div className='col-7' >
      No. of House properties listed ({agentObject?.numberOfHouses})
    </div>
    <div className='col-5' >
    <NavLink className={"blueBtn"} to={`/agent/${agentObject?.id}?q=house`}>Go to list</NavLink>
    </div>
    </div>
    <div className='row mb-3' >
    <div className='col-7' >
    No. of Landed properties listed ({agentObject?.numberOfLands})
    </div>
    <div className='col-5' >
    <NavLink className={"blueBtn"} to={`/agent/${agentObject?.id}?q=land`}>Go to list</NavLink>
    
    </div>
    </div>
  </div>
  </div>}
    {!agentObject?.user_verified && <div className='row'>
    <div className='agentTitle'>Schedule A Tour</div>
    <div className='tourContainer p-3'>
    <Formik 
    innerRef={thisForm}
    initialValues={{
      selectedDate:"",
      selectedTime:"",
      message:"",
      sms:false,
      agree:false,
      propertyId:propertyId
    }}
    validationSchema={TourSchema}
    onSubmit={(values)=>{
      if(CheckIfUserLoggedin().token)
      {
        if(localStorage.getItem(`tour${propertyId}`))
        {
          localStorage.removeItem(`tour${propertyId}`);
        }
      if(values.sms)
      {
      setPaymentAmount("500");
      }else{
        SendMessage(values);
      }
    }else{
      localStorage.setItem(`tour${propertyId}`,JSON.stringify(values));
      SendToast("You must login to able to send message",true);
      setTimeout(()=>{
      navigate(ROUTES.Login)
      },1500)
    }
    }}
    >
      {({values,errors,setFieldValue,handleSubmit})=><>
    <div className='mb-2'>
    <label><small>Date:</small></label>
    <div >
    <DatePicker 
    required
    onChange={(d)=>{
      setFieldValue("selectedDate",d)
    }}
    selected={values.selectedDate === ""? new Date():new Date(String(values.selectedDate))}
    minDate={new Date(Moment().add("d",1).toISOString())}
    maxDate={new Date(Moment().add("d",28).toISOString())}
    />
    </div>
    <div >
    {errors.selectedDate && <label className='tour-error' >{errors.selectedDate}</label>}
    </div>   
    </div>   
    <div className='mb-3'>
    <label>
        <small>Time:</small>
    </label>
    <select
    required
    onChange={(d)=>{
      setFieldValue("selectedTime",d.target.value)
    }}
    value={values.selectedTime}
    name="sat-time" className="form-control roundInput" tabIndex={-98} >
    <option selected value="10:00 am">10:00 am</option>
    <option value="10:15 pm">10:15 pm</option>
    <option value="10:30 pm">10:30 pm</option>
    <option value="12:00 pm">12:00 pm</option>
    <option value="12:15 pm">12:15 pm</option>
    <option value="12:30 pm">12:30 pm</option>
    <option value="12:45 pm">12:45 pm</option>
    <option value="01:00 pm">01:00 pm</option>
    <option value="01:15 pm">01:15 pm</option>
    <option value="01:30 pm">01:30 pm</option>
    <option value="01:45 pm">01:45 pm</option>
    <option value="02:00 pm">02:00 pm</option>
    <option value="05:00 pm">05:00 pm</option>
    </select>
    </div>
    {errors.selectedTime && <label className='tour-error' >{errors.selectedTime}</label>}
    <div className='mb-3' style={{position:"relative"}}>
    <label>
        <small>Message:</small>
    </label>
    <textarea 
    required
    maxLength={120}
    rows={4} 
    className='form-control roundTextarea text-break mb-3'
    placeholder='Hello, I am interested in'
    onChange={(d)=>{
      setFieldValue("message",d.target.value)
    }}
    value={values.message}
    />
    <small style={{fontSize:"0.7rem",position: "absolute",
    right: 10,
    bottom: -18}} className='text-positive'>{values.message.length}/120</small>
    </div> 
    {errors.message && <label className='tour-error mb-2'>{errors.message}</label>}
    <div >
  <div className="form-check" style={{display: "inline-flex"}}>
  <input className="form-check-input"
  onChange={(d)=>{
    setFieldValue("sms",!values.sms)
  }}
  type="checkbox" />
  <label className="form-check-label"  >
  Send via sms - <small>(This will attract the sum of N500 Per SMS)</small>
  </label>
  </div>
  <div className="form-check" style={{display: "inline-flex"}}>
  <input className="form-check-input" type="checkbox" 
  onChange={(d)=>{
    // alert(d.target.value)
    setFieldValue("agree",!values.agree)
    const user = CheckIfUserLoggedin()
    if(user.token)
    {
      setFieldValue("email",user.userData.email);
      setFieldValue("mobile",user.userData.mobile);
    }
  }}
  required
   />
  <label className="form-check-label mb-3"  >
  I consent to having this website store my submitted information so they can respond to my inquiry.
  </label>
  </div>
  {errors.agree && <label className='tour-error'>{errors.agree}</label>}
    </div>
    <div >
      <SubmitButton
      submit={handleSubmit}
      loading={loading}
      title='Submit'
       />
      </div>  
   {paymentAmount && <PaymentView 
    amount={paymentAmount}
    type='tour'
    onClose={()=>{
      setPaymentAmount("")
    }}
    onValue={(data)=>{
      // alert(JSON.stringify(data));
    }}
    memo='Payment for sms charge of N500'
    />}
      </>}
   </Formik>
    </div>
    </div>}
    
    </>
}

export const AgentInfo = (props:UserDataProps)=>{
  return <div >
    <div className='row'>
    <div className='col-3'>
      <img 
      className='agentAvatar'
      src={props?.avatar?props?.avatar:PlaceHolderAvatar}
      />
    </div>
    <div className='col-7 p-4 pt-2' >
      <div className='agentTitle'>Agent</div>
      <div className='agentName'>{props.firstName} {props.lastName}</div>
      {props?.user_verified?<div className='verified mt-2'>
          <CheckIcon />
        <span className='verifiedText'>Verified</span>
      </div>:
      <div className='UnVerified mt-2'>
        <CloseIcon
        color='red'
         />
        <span className='UnVerifiedText'>Unverified</span>
      </div>}
    </div>
    </div>
    <table 
    className='table table-responsive table-bordered mb-5'
    >
     <tr  >
        <td className='t1 p-3'>Mobile:</td>
        <td className='t2 p-3 ps-0'>
          <span className='p-2'>
          <MobileIcon
          size={20}
          />
          </span>{props.user_verified?<NavLink to={`tel:${props.mobile}`} >{props.mobile}</NavLink>:MarkMobileNumber(String(props?.mobile))}
        </td>
      </tr>
      <tr  >
        <td className='t1 p-3'>Email:</td>
        <td className='t2 p-3 ps-0'>
          <span className='p-2'>
         <EmailIcon
          size={20}
          />
          </span>{props.user_verified?<NavLink to={`mailto:${props.email}`} >{props.email}</NavLink>:MarkEmail(String(props.email))}</td>
      </tr>
      <tr  >
        <td className='t1 p-3'>Address:</td>
        <td className='t2 p-3 ps-0'><span className='p-2'>
          <AddressIcon
          size={23}
          />
          </span>{props.user_verified?<a target='_blank' href={`https://map.google.com?q=${props.user_bussiness_address}`} >{props.user_bussiness_address}</a>:"not available."}</td>
      </tr>
    </table>
   
  </div>
}


export const CommentButton = (props:{id:string;postType:"developer"|"house"|"land"})=>{
  const [comment,setComment] = useState<string>("");
  const [saveObj,setSaveObj] = useState<any>({});
  const [loading,setLoading] = useState<boolean>(false);
  const [showModal,setShowModal] = useState<boolean>(false);
  const [searchParams,setSearchParams] = useSearchParams()
const thisDiv = useRef() as RefObject<HTMLDivElement>
 useEffect(()=>{
  const action:string = localStorage.getItem("action") as string;
  if(action)
  {
    const foundObj:ActionProps[] = JSON.parse(action);
    const found = foundObj.find((a,i)=>a.redirect === "developer-details")
  if(searchParams.get("p") && searchParams.get("p") === "comment" && found && found.page === window.location.pathname)
  {
    setShowModal(true)
  }
  }
  try {
    const x = localStorage.getItem("action");
    if(x !== null)
    {
      const v:ActionProps[] = JSON.parse(localStorage.getItem("action")!) as ActionProps[];
      v.forEach((a,i)=>{
        if(a.action === "comment")
        {
          setComment(String(a?.content));
          // setShowModal(true);
        }
      })
    }
   } catch (error) {
    
   }
 return ()=>{

  }
 },[comment])
 if(localStorage.getItem("userData") === null)
 {
   return <></>
 }
 return <>
  <button 
  onClick={()=>{
    const x = localStorage.getItem("action");
    if(x !== null)
    {
      const v:ActionProps[] = JSON.parse(x) as ActionProps[];
      const value:ActionProps | null = v.find((a,i)=>a.action === "comment") as ActionProps | null;
      if(value)
      {
        setComment(String(value?.content));
      }
    }
    setShowModal(true);
  }}
  className='post-comment-button'>
  <span className='px-2'>Post Comment</span><EditIcon size={15} /> </button>
  {showModal && <div 
  ref={thisDiv}
  className="modal" 
  
  tabIndex={-1}>
      <div className="modal-dialog modal-md modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Post a comment</h5>
            <button
              onClick={() => {
                setComment("");
                setShowModal(false);
              }}
              type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div className="modal-body"
      style={{overflow:"hidden"}}
      >
<Formik

initialValues={{
    comment:comment
}}
onSubmit={(values)=>{
  if(localStorage.getItem("token"))
  {
    setLoading(true);
    PostData("post_comment",{
      ...values,
      postId:props.id,
      postType:props.postType
    }).then((res)=>{
        setLoading(false);  
        if(res.status)
        {
            const x = localStorage.getItem("action");
            if(x !== null)
            {
              const v:ActionProps[] = JSON.parse(x) as ActionProps[];
              const value:ActionProps[] = v.filter((a,i)=>a.action !== "comment")
              localStorage.setItem("action",JSON.stringify(value));
            }
         // reload comments
        const reloadEvent = new Event(RELOAD[`${props.postType}`].comment);
        document.dispatchEvent(reloadEvent);
        setComment("");
        setShowModal(false);
        }
       
    })
  }else{
    const v:ActionProps[] = [];
    v[0] = {action:"comment",page:window.location.pathname,content:values.comment,redirect:"developer-details"}
    localStorage.setItem("action",JSON.stringify(v));
    window.location.href = ROUTES.Login+"?redirect="+v[0].redirect;
  }
  return ;  
}}
validationSchema={Schema}
>
{({handleSubmit,handleChange,setFieldValue,errors,values,touched})=><div >
 
 <TextAreaField
  name=""
  label="Comment" 
  max={100}
  required
  value={values.comment}
  onValueChange={(d:any)=>{
  setFieldValue("comment",d.target.value);
  const x:ActionProps = {page:"/"+props.postType+"/"+props.id,action:"comment",content:d.target.value};
  setSaveObj(x);
  try {
    const obj = localStorage.getItem("action");
    if(obj !== null)
    {
      const v:ActionProps[] = JSON.parse(obj) as ActionProps[];
      const nObj = v.map((a:ActionProps,i:number)=>{
        if(a.action === "comment")
        {
         a = x;
        }
        return a;
      })
      const allObj:ActionProps[] = v.find((a:ActionProps,i:number)=>a.action === "comment")?nObj:[...nObj,v] as ActionProps[]
      setSaveObj(x);
      localStorage.setItem("action",JSON.stringify(allObj));
    }else{
      setSaveObj(x); 
      localStorage.setItem("action",JSON.stringify([x]));
      console.log(obj);
    }
   } catch (error) {
    
   }
  }}
  error={touched.comment && errors.comment}
 />
 <SubmitButton
 loading={loading}
 title="Submit"
submit={handleSubmit}
/>
</div>}
</Formik>
  </div>
        </div>
      </div>
    </div>}
  </>
}
