/* eslint-disable no-useless-escape */
/* eslint-disable jsx-a11y/alt-text */
import React, { Fragment, useEffect, useRef, useState } from "react";
import { Currencies, PlaceHolderAvatar } from "../../../includes/constants";
import { CheckBoxButton, InputField, RadioButton, SubmitButton } from "../../../components/forms/inputField";
import { Formik, FormikValues } from "formik";
import * as y from "yup";
import { TransactionHistoryProps, UserDataProps } from "../../../includes/types";
import { GetData, PostData, ReturnComma } from "../../../includes/function";
import { CheckIcon, TrashIcon } from "../../../assets/icons";
import { EditIcon, VerticalDots } from "../../../components/icons";
import DropDown from "../../../components/dropdown";

const DashboardTransactions = () => {
  const [selectedItem,setSelectedItem] = useState<TransactionHistoryProps | null>(null)
  const [userData, setUserData] = useState<UserDataProps>({
    firstName: "",
    lastName: "",
    email: "",
    accountType: "Guest"
  })
  const [transactions, setTransactions] = useState<TransactionHistoryProps[]>([])
  const GetTransactions = () => {
    GetData("transaction_history", {}, false).then((res) => {
    })
  }
  useEffect(() => {
    const u = localStorage.getItem("userData");
    if (u) {
      setUserData(JSON.parse(u))
    }
    GetTransactions();
  }, [])
  return <Fragment>
    <div className="titleText" >Transactions</div>
    <div className="row" >
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6"> 
        <div className="card custom-card"> 
          <div className="card-body"> <div className="row"> 
            <div className="col-6 pe-0"> 
            <p className="mb-2"> 
              <span className="fs-16">Subscription Statistics</span> </p>
              <p className="mb-2 fs-12"> 
                <span className="fs-25 fw-semibold lh-1 vertical-bottom mb-0">153</span> 
                <span className="d-block fs-10 fw-semibold text-muted">THIS MONTH</span> </p>
                <a href="javascript:void(0);" className="fs-12 mb-0 text-primary">Show full stats<i className="ti ti-chevron-right ms-1"></i></a> </div> 
                <div className="col-6"> 
                  <p className="badge bg-success-transparent float-end d-inline-flex">
                    <i className="ti ti-caret-up me-1"></i>42%</p><p className="main-card-icon mb-0">
       <svg className="svg-primary" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" opacity=".3"></path><path d="M0,0h24v24H0V0z" fill="none"></path>
       <g>
        <path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z"></path>
        <rect height="2" width="6" x="9" y="7"></rect>
        <rect height="2" width="2" x="16" y="7"></rect>
        <rect height="2" width="6" x="9" y="10"></rect>
        <rect height="2" width="2" x="16" y="10"></rect>
        </g>
        </svg>
        </p>
        </div> 
        </div> 
        </div> 
        </div> 
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6"> 
        <div className="card custom-card"> 
          <div className="card-body"> <div className="row"> 
            <div className="col-6 pe-0"> 
            <p className="mb-2"> 
              <span className="fs-16">Subscription Statistics</span> </p>
              <p className="mb-2 fs-12"> 
                <span className="fs-25 fw-semibold lh-1 vertical-bottom mb-0">153</span> 
                <span className="d-block fs-10 fw-semibold text-muted">THIS MONTH</span> </p>
                <a href="javascript:void(0);" className="fs-12 mb-0 text-primary">Show full stats<i className="ti ti-chevron-right ms-1"></i></a> </div> 
                <div className="col-6"> 
                  <p className="badge bg-success-transparent float-end d-inline-flex">
                    <i className="ti ti-caret-up me-1"></i>42%</p><p className="main-card-icon mb-0">
       <svg className="svg-primary" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" opacity=".3"></path><path d="M0,0h24v24H0V0z" fill="none"></path>
       <g>
        <path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z"></path>
        <rect height="2" width="6" x="9" y="7"></rect>
        <rect height="2" width="2" x="16" y="7"></rect>
        <rect height="2" width="6" x="9" y="10"></rect>
        <rect height="2" width="2" x="16" y="10"></rect>
        </g>
        </svg>
        </p>
        </div> 
        </div> 
        </div> 
        </div> 
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6"> 
        <div className="card custom-card"> 
          <div className="card-body"> <div className="row"> 
            <div className="col-6 pe-0"> 
            <p className="mb-2"> 
              <span className="fs-16">Subscription Statistics</span> </p>
              <p className="mb-2 fs-12"> 
                <span className="fs-25 fw-semibold lh-1 vertical-bottom mb-0">153</span> 
                <span className="d-block fs-10 fw-semibold text-muted">THIS MONTH</span> </p>
                <a href="javascript:void(0);" className="fs-12 mb-0 text-primary">Show full stats<i className="ti ti-chevron-right ms-1"></i></a> </div> 
                <div className="col-6"> 
                  <p className="badge bg-success-transparent float-end d-inline-flex">
                    <i className="ti ti-caret-up me-1"></i>42%</p><p className="main-card-icon mb-0">
       <svg className="svg-primary" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" opacity=".3"></path><path d="M0,0h24v24H0V0z" fill="none"></path>
       <g>
        <path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z"></path>
        <rect height="2" width="6" x="9" y="7"></rect>
        <rect height="2" width="2" x="16" y="7"></rect>
        <rect height="2" width="6" x="9" y="10"></rect>
        <rect height="2" width="2" x="16" y="10"></rect>
        </g>
        </svg>
        </p>
        </div> 
        </div> 
        </div> 
        </div> 
      </div>
      <div className="col-xl-3 col-lg-6 col-md-6 col-sm-6"> 
        <div className="card custom-card"> 
          <div className="card-body"> <div className="row"> 
            <div className="col-6 pe-0"> 
            <p className="mb-2"> 
              <span className="fs-16">Subscription Statistics</span> </p>
              <p className="mb-2 fs-12"> 
                <span className="fs-25 fw-semibold lh-1 vertical-bottom mb-0">153</span> 
                <span className="d-block fs-10 fw-semibold text-muted">THIS MONTH</span> </p>
                <a href="javascript:void(0);" className="fs-12 mb-0 text-primary">Show full stats<i className="ti ti-chevron-right ms-1"></i></a> </div> 
                <div className="col-6"> 
                  <p className="badge bg-success-transparent float-end d-inline-flex">
                    <i className="ti ti-caret-up me-1"></i>42%</p><p className="main-card-icon mb-0">
       <svg className="svg-primary" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z" opacity=".3"></path><path d="M0,0h24v24H0V0z" fill="none"></path>
       <g>
        <path d="M19.5,3.5L18,2l-1.5,1.5L15,2l-1.5,1.5L12,2l-1.5,1.5L9,2L7.5,3.5L6,2v14H3v3c0,1.66,1.34,3,3,3h12c1.66,0,3-1.34,3-3V2 L19.5,3.5z M19,19c0,0.55-0.45,1-1,1s-1-0.45-1-1v-3H8V5h11V19z"></path>
        <rect height="2" width="6" x="9" y="7"></rect>
        <rect height="2" width="2" x="16" y="7"></rect>
        <rect height="2" width="6" x="9" y="10"></rect>
        <rect height="2" width="2" x="16" y="10"></rect>
        </g>
        </svg>
        </p>
        </div> 
        </div> 
        </div> 
        </div> 
      </div>
    </div>
  
    <div className="card custom-card mt-3">
      <div className="card-header justify-content-between">
        <div className="card-title"> Transaction Overview </div>
        <div className="d-sm-flex">
          <div className="me-3 mb-3 mb-sm-0">
            <input className="form-control form-control-sm" type="text" placeholder="Search" aria-label=".form-control-sm example" />
          </div>
          <div className="dropdown">
            <a href="javascript:void(0);" className="btn btn-primary btn-sm btn-wave waves-effect waves-light" data-bs-toggle="dropdown" aria-expanded="false"> Sort By<i className="ri-arrow-down-s-line align-middle ms-1 d-inline-block"></i>
            </a>
            <ul className="dropdown-menu" role="menu"> <li>
              <a className="dropdown-item" href="javascript:void(0);">New</a></li>
              <li><a className="dropdown-item" href="javascript:void(0);">Popular</a></li>
              <li><a className="dropdown-item" href="javascript:void(0);">Relevant</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="card-body">
        <div className="table-responsive">
          <table className="table text-nowrap table-bordered">
            <thead>
              <tr>
                <th scope="col">Description</th>
                <th scope="col">Amount</th>
                <th scope="col">Status</th>
                <th scope="col">Type</th>
                <th scope="col">Created At</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
            {transactions.map((item,index)=><tr key={index}>
                <td>
                  <div className="d-flex align-items-center">
                    <div className="me-2 lh-1">
                      <span className="avatar avatar-sm">
                        <img src="../assets/images/ecommerce/png/36.png" alt="" />
                      </span>
                    </div>
                    <div className="fs-14">Niker College Bag</div>
                  </div>
                </td>
                <td>{Currencies.naira.symbol}{ReturnComma(String(item.amount))}</td>
                <td></td>
                <td></td>
                <td></td>
                <td >
                  <button 
                  className="btn"
                  onClick={()=>setSelectedItem(item)}
                  >
                  <VerticalDots 
                  size={20}
                  color="black"
                  />
                  </button>
                  {selectedItem &&<DropDown
                  onValue={(d)=>{
                    setSelectedItem(null);
                  }}
                  style={{position:"absolute",right:120,top:150}}
                  list={[
                    {title:"Delete",route:"",description:"",icon:<TrashIcon size={16} />}
                  ]}
                  />}
                </td>
              </tr>)}
            </tbody>
          </table>
        </div>
      </div>
      <div className="card-footer">
        <div className="d-flex align-items-center">
          <div> Showing 5 Entries <i className="bi bi-arrow-right ms-2 fw-semibold"></i> </div>
          <div className="ms-auto">
            <nav aria-label="Page navigation" className="pagination-style-4">
              <ul className="pagination mb-0">
                <li className="page-item disabled">
                  <a className="page-link" href="javascript:void(0);"> Prev </a>
                </li>
                <li className="page-item active"><a className="page-link" href="javascript:void(0);">1</a></li>
                <li className="page-item"><a className="page-link" href="javascript:void(0);">2</a></li>
                <li className="page-item"> <a className="page-link text-primary" href="javascript:void(0);"> next </a> </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </Fragment>
}
export default DashboardTransactions;