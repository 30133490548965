/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-var */
import React, { Fragment, useEffect, useState } from "react";
import { NavLink, Outlet, useLocation, Navigate, useNavigate } from "react-router-dom";
import { PlaceHolderAvatar, ROUTES, ReducerObjs, SideBarLinks, TabLinks, blueColor } from "../../includes/constants";
import './style.css';
import { useDispatch } from "react-redux";
import { GetUserInfo } from "../../redux/userSlice";
import { AccountSettingIcon, BellIcon, EmailIcon, MailIcon, PowerIcon, SupportIcon, WalletIcon } from "../../components/icons";
import DropDown from "../../components/dropdown";
import { CaretBackIcon, MapPinIcon } from "../../assets/icons";
import { ArrowDownIcon } from "../../components/footer/icons";
import { NewListing } from "../../components/newlisting";

const Dashboard = () => {
  const [hideText,setHideText] = useState<boolean>(false)
  const [showPostNew,setPostNew] = useState<boolean>(false)
  const [showNotifications,setShowNotifications] = useState<boolean>(false)
  const [showMails,setShowMails] = useState<boolean>(false)
  const [showSettings,setShowSettings] = useState<boolean>(false)
  const [selectedTab,setSelectedTab] = useState<string>("")
  const navigate = useNavigate();
  const userData = GetUserInfo();
  const dispatch = useDispatch()
  useEffect(() => {
    const u: string | null = localStorage.getItem("userData")
    if (u) {
      dispatch({ type: ReducerObjs.UpdateUser, payload: JSON.parse(u) })
    }
  }, [])
  if (!localStorage.getItem("userData")) {
    return <Navigate
      to={ROUTES.Home}
    />
  }

  return <div className="d-inline-flex" style={{ width: "100vw" }}>
    <div 
    className="sidebar">
      {SideBarLinks.filter((a, i) => a.accountTypes.includes(userData?.accountType!)).map((item, i) => {
        return <div 
        onClick={()=>{
          navigate(item.route);
          setSelectedTab(item.route)
        }}
        key={i} 
        // to={String(ROUTES.UserDashboard + item.route)} 
        className={i === 0?"sidebar-active":selectedTab === item.route ?"sidebar-hover":""}
        >
          <span className="pe-2 fs-4" >{item.icon ? item.icon(selectedTab === item.route ? "White" : "black") : null}</span>
          <span style={{color:i === 0?"white":selectedTab === item.route ?"white":"black"}}>{hideText?null:item.title}</span></div>
      })}
    </div>
    <div className="bashboard-main position-relative" >
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <button 
          onClick={()=>setHideText(!hideText)}
          className="btn" 
          type="button" >
          <MenuIcon /> 
          </button>
            <div className="d-flex align-items-center" >
            {TabLinks.map((a,i)=><div className="nav-item">
                <NavLink className="nav-link" aria-current="page" to={a.route} >{a.title}</NavLink>
              </div>)}
              <div className="d-flex align-items-center">
              <span
            onClick={()=>{
              setShowSettings(false);
              setShowNotifications(false);
              setShowMails(false);
              setPostNew(true)
            }}
            className="blueBtn" style={{marginLeft:10}}>
              Post a property
              </span>
             <button 
             className="btn py-0"
             onClick={()=>{
              setShowSettings(false);
              setShowNotifications(false);
              setShowMails(!showMails);
             }}

             >
              <EmailIcon 
              size={25}
              />
             </button>
             <button 
             className="btn py-0"
             onClick={()=>{
              setShowSettings(false);
              setShowMails(false);
              setShowNotifications(!showNotifications);
             }}
             >
              <BellIcon 
              color="black"
              size={25}
              />
             </button>
             <button
              onClick={()=>{
                setShowMails(false);
                setShowNotifications(false);
                setShowSettings(!showSettings);
              }} 
              className="btn p-0 m-0 d-flex align-items-center"
             >
             <div 
             className="avatar ms-2 p-0" >
              <img src={userData?.avatar?userData?.avatar:PlaceHolderAvatar} />
              </div>
              <div 
              className="px-2 text-start">
                <div className="p-0 m-0 fs-6" style={{lineHeight:1}}>{userData.firstName} {userData.lastName}<br/><small className="fs-8">{userData.accountType}</small></div>
              </div>
                <ArrowDownIcon 
                size={25}
                />
              </button>
              
              </div>
              {showNotifications &&<DropDown 
              title="Notifications"
              style={{right:230,position:"absolute"}}
              list={[
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"}
              ]}
             />}
              {showMails &&<DropDown 
              title="Messages"
              style={{right:280,position:"absolute"}}
              list={[
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"},
                {route:"",title:"Settings"}
              ]}
             />}
              {showSettings &&<DropDown 
              style={{right:20,position:"absolute"}}
              list={[
                {route:ROUTES.UserDashboard+ROUTES.AccountSettings,title:"Profile Settings",icon:<div 
               className="icon-w icon-w-danger"
               >
                  <AccountSettingIcon 
                  size={25}
                  color="black"
                  />
                  </div>},
                {route:"",title:"Inbox",icon:<div 
                  className="icon-w icon-w-danger"
                  >
                     <MailIcon
                     size={25}
                     color="black"
                     />
                     </div>},
                {route:"",title:"Support",icon:<div 
                  className="icon-w icon-w-info"
                  >
                     <SupportIcon 
                     size={25}
                     color="black"
                     />
                     </div>,},
                {route:"",title:"Bal: NGN 0.00",icon:<div 
                  className="icon-w icon-w-success"
                  >
                     <WalletIcon 
                     size={25}
                     color="black"
                     />
                     </div>,},
                {route:"",title:"Log Out",icon:<div 
                  className="icon-w icon-w-danger"
                  >
                     <PowerIcon 
                     size={23}
                     />
                     </div>}
              ]}
              onClose={()=>setShowSettings(false)}
             />}
            </div>
        </div>
       
      </nav>
      
      <div className="p-3" style={{height:"90vh",overflow:"scroll",backgroundColor:"#e7e7e7"}} >
        <Outlet
        />
      </div>
    </div>
    {showPostNew && <NewListing 
onClose={()=>{
  setPostNew(false)
}}
/>}
  </div>
}

export default Dashboard;

const MenuIcon = () => {
  return <svg 
  width={30}
  height={30}
  viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg"  fill="#000000">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier"> 
      <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> 
        <g id="Menu"> 
          <rect id="Rectangle" fill-rule="nonzero" x="0" y="0" width="24" height="24"> </rect> 
          <line x1="5" y1="7" x2="19" y2="7" id="Path" stroke="#0C0310" stroke-width="2" stroke-linecap="round"> </line>
           <line x1="5" y1="17" x2="19" y2="17" id="Path" stroke="#0C0310" stroke-width="2" stroke-linecap="round"> </line> 
           <line x1="5" y1="12" x2="19" y2="12" id="Path" stroke="#0C0310" stroke-width="2" stroke-linecap="round"> </line> 
           </g> 
           </g> 
           </g>
           </svg>
}