/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react';
import BaseModal from '../baseModal';
import { RefObject, useEffect, useRef, useState } from 'react';
import "./style.css";
import { SubmitButton } from '../forms/inputField';
import Cropper  from "react-easy-crop";
import getCroppedImg from './saveImage';

// get default properties
export interface ImageEditorComponentProp {
onClose:()=>void;
file:File | null;
onValue:(data:File)=>void;
}
interface CropProp {
    x:number;
    y:number;
}

const ImageEditorComponent = (prop:ImageEditorComponentProp)=>{
    const aspectRatios = [
        {value:4/3,text:"4/3"},
        {value:16/9,text:"16/9"},
        {value:1/2,text:"1/2"}
    ]
    const thisCropper = useRef() as RefObject<Cropper>;
    const editorWrapper  = useRef() as RefObject<HTMLDivElement>;
    const [imageHeight,setHeight] = useState<number>(0);
    const [imageWidth,setWidth] = useState<number>(0);
    const [aspectRatio,setAspectRatio] = useState<number>(aspectRatios[0].value);
    const [crop,setCrop] = useState<CropProp>({x:15,y:0});
    const [croppedAreaPixel,setCroppedAreaPixel] = useState(null);
    const [brigtness,setBrightness] = useState<number>(0);
    const [saturation,setSaturation] = useState<number>(0);
    const [inversion,setInversion] = useState<number>(0);
    const [zoom,setZoom] = useState<number>(1);
    const [imageSource,setImageSource] = useState<string>("");
    const onSave = async () => {
        const croppedImageUrl:any = await getCroppedImg(imageSource, croppedAreaPixel);
        prop.onValue(croppedImageUrl.blog)
      };
    const onCropComplete = (croppedArea:any, croppedAreaPixels:any) => {
        console.log(croppedArea, croppedAreaPixels);
        setCroppedAreaPixel(croppedAreaPixels);
      };
    const handleEditorProcess = (imageState:any) => {
        // setEditorResult(URL.createObjectURL(imageState.dest));
        // editorCanvas.current?.toDataURL()
        console.log(imageState)
        prop.onValue(imageState.dest);
    };
    const handleZoom = (value:number)=>{
        setZoom(value);
    }
    const handleOnZoomChange = (value:number)=>{
        setZoom(value);
    }
    const handleBrightness = (value:number)=>{
        setBrightness(value);
    }
    const handleSaturation = (value:number)=>{
        setSaturation(value);
    }
   
    const handleInversion = (value:number)=>{
        setInversion(value);
    }
    const handleWidth = (value:number)=>{
        setWidth(value);
    }
    const handleHeight = (value:number)=>{
        setHeight(value);
    }

    const handleOnCropChange = (crop:any)=>{
           setCrop(crop);
    }
    useEffect(()=>{
       const w =  parseFloat(String(editorWrapper.current?.clientWidth));
       const h =  parseFloat(String(editorWrapper.current?.clientHeight));
       setWidth(w);
       setHeight(h);
    },[imageHeight,imageWidth])
    if(!prop.file)
    {
        return null;
    }

    const settings = {
        brigtness,
        saturation,
        inversion,
        height:imageHeight,
        width:imageWidth
    }
    useEffect(()=>{
        if(prop.file)
        {
        const reader = new FileReader();
        reader.onload = function({target}) {
        // console.log(target?.result);
        setImageSource(String(target?.result));
        };
        reader.readAsDataURL(prop.file);
    }
    },[prop.file])
    return  <BaseModal 
        onClose={prop.onClose}
        type="xl"
        center
        title="Image Editor"
        >
        <div className="editorContainer" >
         <div  className='editorWrapper' >
         <Cropper
         ref={thisCropper}
         zoom={1+(zoom/100)}
         crop={crop}
         cropSize={{
            height:350,
            width:850
         }}
         aspect={aspectRatio}
         image={imageSource}
         onCropChange={handleOnCropChange}
         onZoomChange={handleOnZoomChange}
         onCropComplete={onCropComplete}
         /> 
         <div className='card mb-3 zoomWrap d-flex justify-content-center'>
            <span style={{fontSize:10,position:"absolute"}}>Zoom</span>
            <div 
            style={{paddingLeft:35,paddingTop:3}}
            >
            <input 
            value={zoom}
            min={1}
            max={100}
            onChange={({target})=>{
                console.log(target.value);
                handleZoom(parseInt(target.value))
            }} 
            type='range'
            style={{width:"100%"}}
             />
             </div>
            </div>
         </div>
         <div className='editorToolContainer px-3'>
            <div className='card p-2 mb-3'>
            <label >Aspect Ratios</label>
           <div 
           className='row mt-1 p-2 '
           >
            {aspectRatios.map((a,i)=><div key={i} className=' col-4 m-0 p-1'>
            <div
            onClick={()=>setAspectRatio(a.value)}
            className={`card btn d-flex align-items-center justify-content-center p-3 ${aspectRatio === a.value?"blueBtn":""}`} style={{height:40,borderRadius:8,width:"auto"}}>
                {a.text}
            </div> 
            </div>)}
           </div>
            </div>
            <div className='card p-2 mb-3'>
            <label >Brightness</label>
            <input 
            value={brigtness} 
            type='range'
            onChange={({target})=>{
                handleBrightness(parseInt(target.value))
            }}
            />
            </div>
            <div className='card p-2 mb-3'>
            <label >Saturation</label>
            <input value={saturation}
            onChange={({target})=>{
                handleSaturation(parseInt(target.value))
            }} type='range' />
            </div>
            <div className='card p-2 mb-3'>
            <label >Inversion</label>
            <input
             value={inversion} 
            onChange={({target})=>{
                handleInversion(parseInt(target.value))
            }} type='range' />
            </div>
            <div className='p-2 mb-3'>
            {croppedAreaPixel &&<SubmitButton 
            submit={onSave}
            title='Save'
            fill
            />}
         </div>
         </div>
         </div>
         </BaseModal>
}
export default ImageEditorComponent;